import React from 'react'
import PropTypes from 'prop-types'
import { Title } from './styles'

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
}
const defaultProps = {
  className: '',
  style: {},
}

export function AppLogo({ className, style }) {
  return (
    <Title className={className} style={style}>
      Deep Art
    </Title>
  )
}

AppLogo.propTypes = propTypes
AppLogo.defaultProps = defaultProps
