import { styled } from 'linaria/react'

export const Wrapper = styled.div`
  position: relative;
  padding: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 276px);
`

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;

  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border: 1px solid #292929;
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  & button {
    margin: 0 10px;
  }
`

export const BackButton = styled.button`
  position: relative;
  border: 2px solid #292929;
  font-size: 20px;
  color: #292929;
  font-weight: 500;
  box-shadow: 0 0 4px 1px rgba(41, 41, 41, 0.5);
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.25s;
  outline: none;

  height: 45px;
  width: 45px;

  & svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    transform: rotate(-1deg) scale(1.08);
  }

  &:active {
    transform: scale(0.9) rotate(0deg);
  }
`
