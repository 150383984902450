import { styled } from 'linaria/react'

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Element = styled.div`
  font-size: ${props => props.size}px;

  background: #292929;
  animation: loadAnimation 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  text-indent: -9999em;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s;
  color: #292929;
  margin: 0;

  &:before,
  &:after {
    background: #292929;
    width: 1em;
    height: 4em;
    position: absolute;
    top: 0;
    content: '';
  }

  @media (prefers-reduced-motion: no-preference) {
    &:before,
    &:after {
      animation: loadAnimation 1s infinite ease-in-out;
    }
  }

  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1.5em;
  }

  @keyframes loadAnimation {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }

    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`
