import { css } from 'linaria'
import { styled } from 'linaria/react'

export const webcamButton = css`
  display: inline-block;
  border: 2px solid #292929;
  margin-left: 10px;
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  & button {
    margin: 0 10px;
  }

  padding-top: 32px;
`

export const container = css`
  height: 562px;
  width: 749px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: #292929;
`
export const BackButton = styled.button`
  position: relative;
  border: 2px solid #292929;
  font-size: 20px;
  color: #292929;
  font-weight: 500;
  box-shadow: 0 0 4px 1px rgba(41, 41, 41, 0.5);
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.25s;
  outline: none;

  height: 45px;
  width: 45px;

  & svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    transform: rotate(-1deg) scale(1.08);
  }

  &:active {
    transform: scale(0.9) rotate(0deg);
  }
`
