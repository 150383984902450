import { styled } from 'linaria/react'

export const Arrow = styled.button`
  &.slick-arrow {
    width: 35px;
    height: 65px;
    color: #fff;
    z-index: 1000;
    right: ${props => (props.direction === 'next' ? '0' : undefined)};
    left: ${props => (props.direction === 'prev' ? '0' : undefined)};
    border-radius: ${props => (props.direction === 'prev' ? '0 8px 8px 0' : '8px 0 0 8px')};
  }

  &.slick-arrow,
  &.slick-arrow:hover,
  &.slick-arrow:focus {
    background-color: #29180cc7;
    color: #fff;
  }

  /* stylelint-disable a11y/no-display-none, a11y/content-property-no-static-value */

  &.slick-arrow:before {
    display: none;
    content: none;
  }

  /* stylelint-enable a11y/no-display-none, a11y/content-property-no-static-value */

  &.slick-arrow.slick-disabled {
    opacity: 0;
  }

  & svg {
    transform: ${props => (props.direction === 'prev' ? 'rotate(0deg)' : 'rotate(180deg)')};
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (prefers-reduced-motion: no-preference) {
    &.slick-arrow {
      transition: opacity 0.25s;
    }
  }
`
