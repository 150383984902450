import React, { useLayoutEffect } from 'react'
import { StoreContext } from 'storeon/react'
import { isAndroid, isIOS, IOS_LINK, ANDROID_LINK } from 'lib/mobile'
import { store } from './Store'
import { Routes } from './Routes'
import './GlobalStyles.css'

const App = () => {
  useLayoutEffect(() => {
    if (isAndroid()) {
      window.location = ANDROID_LINK
    }
    if (isIOS()) {
      window.location = IOS_LINK
    }
  }, [])

  return (
    <StoreContext.Provider value={store}>
      <Routes />
    </StoreContext.Provider>
  )
}

export default App
