import { exponentialBackoff } from 'lib/async'
import * as api from './effects.requests'

const initialState = {
  items: [],
  history: [],
}

export function effectsModuleStore(store) {
  store.on('@init', () => ({ effects: initialState }))

  store.on('effects/load-effects', async ({ errors }) => {
    try {
      setImmediate(() => {
        if (errors.effects.loading) {
          store.dispatch('errors/set-error', { effects: { loading: false } })
        }
        store.dispatch('statuses/set-status', { effects: { loading: true } })
      })

      const {
        data: { data: items },
      } = await api.getEffects()
      const imgEffects = items.filter(item => item.mediaType.toLowerCase() === 'image')
      store.dispatch('effects/set-items', imgEffects)
    } catch (e) {
      store.dispatch('errors/set-error', { effects: { loading: true } })
      console.error(e)
    } finally {
      store.dispatch('statuses/set-status', { effects: { loading: false } })
    }
  })

  store.on('effects/set-items', ({ effects }, items) => {
    return { effects: { ...effects, items } }
  })

  store.on('effects/set-history', ({ effects }, history) => {
    return { effects: { ...effects, history } }
  })

  store.on(
    'effects/apply-effect',
    async ({ errors, picture: { originId }, effects: { history } }, effectId) => {
      try {
        setImmediate(() => {
          if (errors.effects.processing) {
            store.dispatch('errors/set-error', { effects: { processing: false } })
          }
          store.dispatch('statuses/set-status', { effects: { applying: true } })
        })

        const historyItem = history.find(
          item => item.effectId === effectId && item.imgId === originId,
        )

        if (historyItem) {
          setImmediate(() => {
            store.dispatch('picture/set-with-effect', historyItem.imgUrl)
            store.dispatch('statuses/set-status', { effects: { applying: false } })
          })
        } else {
          const {
            headers: { location },
          } = await api.runOperation(originId, effectId)

          const operationId = location.split('/').pop()
          const withEffectImgUrl = await exponentialBackoff(
            () => api.getResourceUrlFromOperation(operationId),
            100,
            2000,
            200,
          )
          store.dispatch('picture/set-with-effect', withEffectImgUrl)

          const historyResult = { effectId, imgId: originId, imgUrl: withEffectImgUrl }
          history.push(historyResult)
          store.dispatch('effects/set-history', history)
        }
      } catch (e) {
        store.dispatch('errors/set-error', { effects: { processing: true } })
        console.error(e)
      } finally {
        store.dispatch('statuses/set-status', { effects: { applying: false } })
      }
    },
  )
}
