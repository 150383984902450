import { styled } from 'linaria/react'

export const Aside = styled.aside`
  padding: 15px 20px;
  background-color: transparent;
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`

export const Link = styled.a`
  text-decoration: none;
  margin: 0 10px;
  padding: 5px;

  img {
    width: 135px;
  }
`
