import React, { useState } from 'react'
import Webcam from 'react-webcam'
import { useStoreon } from 'storeon/react'

import { TakePictureButton, Spinner } from 'components/atoms'
import { ReactComponent as BackIcon } from 'assets/icons/back-arrow.svg'

import { webcamButton, container, BackButton, Actions } from './styles'

const videoConstraints = {
  width: 800,
  height: 600,
  facingMode: 'user',
}

export function WebcamCapture() {
  const { dispatch } = useStoreon('picture')
  const { statuses } = useStoreon('statuses')

  // Link to the camera component in imperative style
  const webcamRef = React.useRef(null)

  function b64toBlob(b64Data) {
    const contentType = 'image/jpeg'
    const sliceSize = 512

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  const capture = React.useCallback(() => {
    let imageSrc = webcamRef.current.getScreenshot()
    imageSrc = imageSrc.substring(imageSrc.indexOf(',') + 1)

    const blob = b64toBlob(imageSrc)

    const file = new File([blob], 'Picture.jpeg', { type: 'image/jpeg', lastModified: Date.now() })
    dispatch('picture/load', file)
  }, [webcamRef, dispatch])

  const [hiddenCamera, setHiddenCamera] = useState(true)

  function hideSpinner() {
    setHiddenCamera(false)
  }

  const userMediaContent = (
    <>
      <div className={container}>
        <Webcam
          audio={false}
          height={562}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={749}
          videoConstraints={videoConstraints}
          onUserMedia={hideSpinner}
        />
      </div>

      {hiddenCamera ? (
        <Spinner size={10} />
      ) : (
        <Actions>
          <BackButton
            title="Back to image loading"
            onClick={() => {
              dispatch('changeSource')
            }}
          >
            <BackIcon />
          </BackButton>
          <TakePictureButton
            className={webcamButton}
            isLoading={statuses.picture.uploading}
            clickFn={capture}
          />
        </Actions>
      )}
    </>
  )

  return <>{userMediaContent}</>
}
