import { styled } from 'linaria/react'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100vh;
  padding: 50px;
  color: #292929;
`

export const Text = styled.h3`
  margin-top: 0;
  color: #292929;
`
