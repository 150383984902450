import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useStoreon } from 'storeon/react'
import { Spinner } from 'components/atoms'
import { Container } from './styles'

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

export function Dropzone({ isLoading }) {
  const { dispatch } = useStoreon('picture')
  const { draggedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    multiple: false,
    accept: 'image/jpeg, image/png, image/jpg',
    onDropAccepted: files => {
      dispatch('picture/load', files[0])
    },
  })
  const isUsedMultipleFiles = isDragReject && draggedFiles.length > 1

  return (
    <Container
      {...getRootProps({ isDragAccept, isDragReject })}
      isUsedMultipleFiles={isUsedMultipleFiles}
      isLoading={isLoading}
    >
      <input {...getInputProps()} />

      {isLoading && <Spinner />}

      {isUsedMultipleFiles && <p>You can choose only one file at the same time</p>}

      {!isUsedMultipleFiles && isDragReject && (
        <p>You can choose only file in jpg, jpeg, png formats</p>
      )}

      {!isUsedMultipleFiles && !isDragReject && !isLoading && (
        <p>Drag &apos;n&apos; drop some file here, or click to select file</p>
      )}
    </Container>
  )
}

Dropzone.propTypes = propTypes
