const initialState = {
  picture: {
    uploading: false,
  },
  effects: {
    applying: false,
    loading: false,
  },
  pictureSource: {
    fromCamera: false,
  },
}

export function statusesModuleStore(store) {
  store.on('@init', () => ({ statuses: initialState }))

  store.on('statuses/set-status', ({ statuses }, status) => {
    const nextState = { ...statuses }

    Object.keys(status).forEach(key => {
      nextState[key] = { ...statuses[key], ...status[key] }
    })

    return { statuses: nextState }
  })

  store.on('changeSource', ({ statuses }) => ({
    statuses: {
      picture: {
        uploading: statuses.picture.uploading,
      },
      effects: {
        applying: statuses.effects.applying,
        loading: statuses.effects.loading,
      },
      pictureSource: {
        fromCamera: !statuses.pictureSource.fromCamera,
      },
    },
  }))
}
