import React from 'react'
import PropTypes from 'prop-types'
import { Message } from './styles'

const propTypes = {
  text: PropTypes.string.isRequired,
}

export function ErrorMessage({ text }) {
  return <Message>{text}</Message>
}

ErrorMessage.propTypes = propTypes
