import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Element } from './styles'

const propTypes = {
  size: PropTypes.number,
}
const defaultProps = {
  size: 11,
}

export function Spinner({ size }) {
  return (
    <Wrapper>
      <Element size={size} />
    </Wrapper>
  )
}

Spinner.propTypes = propTypes
Spinner.defaultProps = defaultProps
