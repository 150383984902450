import { styled } from 'linaria/react'

export const Effect = styled.button`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 10px 0 0;
  width: 200px;
  height: 200px;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    width: 100%;
    height: 37px;
  }
`

export const Title = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 10px;
  font-size: 15px;
  color: #292929;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.8);
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
