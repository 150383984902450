import { styled } from 'linaria/react'
import Slider from 'react-slick'

export const ResponsiveCarousel = styled(Slider)`
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
  margin-top: auto;
`

export const EmptyContentWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: auto;
  height: 200px;
  border-top: 1px solid #292929;
`
