import React from 'react'
import PropTypes from 'prop-types'
import { Spinner, SavePictureButton } from 'components/atoms'
import { downloadPicture } from 'lib/file'
import { ReactComponent as BackIcon } from 'assets/icons/back-arrow.svg'
import { Wrapper, Img, Actions, BackButton } from './styles'

const propTypes = {
  origin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  withEffect: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isApplying: PropTypes.bool.isRequired,
  dropOriginFn: PropTypes.func.isRequired,
}
const defaultProps = {
  origin: null,
  withEffect: null,
}

export function ImageView({ origin, withEffect, isApplying, dropOriginFn }) {
  const imageUrl = withEffect || origin

  return !isApplying ? (
    <>
      <Wrapper>
        <Img src={imageUrl} alt="" />
      </Wrapper>
      <Actions>
        <BackButton title="Back to image loading" onClick={dropOriginFn}>
          <BackIcon />
        </BackButton>
        <SavePictureButton title="Save image file" onClickFn={() => downloadPicture(imageUrl)} />
      </Actions>
    </>
  ) : (
    <Spinner />
  )
}

ImageView.propTypes = propTypes
ImageView.defaultProps = defaultProps
