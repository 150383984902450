import React from 'react'
import storeonRouter from '@storeon/router'
import { useStoreon } from 'storeon/react'
import { HomePage, EffectsPage, NotFoundPage } from 'components/pages'

export const router = storeonRouter.createRouter([
  ['/', () => ({ page: 'load' })],
  ['/load', () => ({ page: 'load' })],
  ['/effects', () => ({ page: 'effects' })],
])

export function Routes() {
  const { [storeonRouter.key]: route } = useStoreon(storeonRouter.key)

  switch (route.match.page) {
    case 'load':
      return <HomePage />

    case 'effects':
      return <EffectsPage />

    default:
      return <NotFoundPage />
  }
}
