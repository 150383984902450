import React from 'react'
import PropTypes from 'prop-types'
import { EffectPropTypes } from 'services/Effects/effects.proptypes'
import { CarouselArrow, Spinner } from 'components/atoms'
import { EffectCard } from 'components/molecules'
import { ResponsiveCarousel, EmptyContentWrapper } from './styles'

const propTypes = {
  effects: PropTypes.arrayOf(EffectPropTypes).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export function Carousel({ isLoading, effects }) {
  if (isLoading) {
    return (
      <EmptyContentWrapper>
        <Spinner />
      </EmptyContentWrapper>
    )
  }

  return (
    <ResponsiveCarousel
      dots={false}
      arrows
      initialSlide={0}
      slidesToScroll={2}
      infinite
      speed={300}
      swipe={false}
      variableWidth
      rows={1}
      prevArrow={<CarouselArrow direction="prev" />}
      nextArrow={<CarouselArrow direction="next" />}
    >
      {effects.map(effect => (
        <EffectCard key={effect.id} effect={effect} />
      ))}
    </ResponsiveCarousel>
  )
}

Carousel.propTypes = propTypes
