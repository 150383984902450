import React from 'react'
import { useStoreon } from 'storeon/react'
import { EffectPropTypes } from 'services/Effects/effects.proptypes'
import { Effect, Title, Image } from './styles'

const propTypes = {
  effect: EffectPropTypes.isRequired,
}

export function EffectCard({ effect: { id, name, icon } }) {
  const { dispatch } = useStoreon('effects')

  return (
    <Effect type="button" onClick={() => dispatch('effects/apply-effect', id)}>
      <Image src={icon} alt={name} />
      <Title>{name}</Title>
    </Effect>
  )
}

EffectCard.propTypes = propTypes
