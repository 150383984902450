export const IOS_LINK = 'https://apps.apple.com/us/app/deep-art/id1503178585?ls=1'
export const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.akvelon.deepart'

export function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export function isAndroid() {
  return navigator.userAgent.toLowerCase().indexOf('android') > -1
}
