import { API } from 'lib/api'

export const uploadImage = file => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }

  const data = new FormData()
  data.append('file', file)

  return API.post('/media', data, config)
}
