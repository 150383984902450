import React from 'react'
import PropTypes from 'prop-types'
import appleMarketImg from 'assets/images/get-from-apple-market.svg'
import googleMarketImg from 'assets/images/get-from-google-market.svg'
import { IOS_LINK, ANDROID_LINK } from 'lib/mobile'

import { Aside, Link } from './styles'

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
}
const defaultProps = {
  className: '',
  style: {},
}

export function MobileAppsAside({ className, style }) {
  return (
    <Aside className={className} style={style}>
      <Link href={IOS_LINK} target="_blank" rel="noopener noreferrer">
        <img src={appleMarketImg} alt="Apple Market Icon" />
      </Link>

      <Link href={ANDROID_LINK} target="_blank" rel="noopener noreferrer">
        <img src={googleMarketImg} alt="Google Market Icon" />
      </Link>
    </Aside>
  )
}

MobileAppsAside.propTypes = propTypes
MobileAppsAside.defaultProps = defaultProps
