export function delay(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export async function exponentialBackoff(action, start, max, step = 250) {
  if (!max || max < start) {
    throw new Error(
      'You should specify correct max value for exponentialBackoff (max && max > start).',
    )
  }

  let time = start
  let result = -1

  while (time <= max) {
    result = await action()
    if (result !== -1) {
      break
    }

    await delay(time)
    time += step

    if (time > max) {
      time = max
    }
  }

  return result
}
