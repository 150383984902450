import { styled } from 'linaria/react'

export const Content = styled.article`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex-grow: 1;
  padding: 30px 20px 50px 20px;
  max-width: 100vw;
`

export const BackButton = styled.button`
  position: relative;
  border: 2px solid #292929;
  font-size: 20px;
  color: #292929;
  font-weight: 500;
  box-shadow: 0 0 4px 1px rgba(41, 41, 41, 0.5);
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.25s;
  outline: none;

  height: 45px;
  width: 45px;

  & svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    transform: rotate(-1deg) scale(1.08);
  }

  &:active {
    transform: scale(0.9) rotate(0deg);
  }
`
