import router from '@storeon/router'
import { convertFileObjectIntoDataUrl } from 'lib/file'
import * as api from './picture.requests'

const initialState = {
  origin: null,
  originId: null,
  withEffect: null,
}

export function pictureModuleStore(store) {
  store.on('@init', () => ({ picture: initialState }))

  store.on('picture/drop-origin', ({ picture }) => {
    return {
      picture: {
        ...picture,
        origin: initialState.origin,
        originId: initialState.originId,
        withEffect: initialState.withEffect,
      },
    }
  })

  store.on('picture/set-origin', ({ picture }, { file, id }) => {
    return {
      picture: { ...picture, origin: file, originId: id, withEffect: initialState.withEffect },
    }
  })

  store.on('picture/set-with-effect', ({ picture }, url) => {
    return { picture: { ...picture, withEffect: url } }
  })

  store.on('picture/load', async ({ errors }, file) => {
    try {
      setImmediate(() => {
        if (errors.picture.uploading) {
          store.dispatch('errors/set-error', { picture: { uploading: false } })
        }
        store.dispatch('statuses/set-status', { picture: { uploading: true } })
      })

      const {
        headers: { location },
      } = await api.uploadImage(file)

      const origin = {
        file: await convertFileObjectIntoDataUrl(file),
        id: location.split('/').pop(),
      }

      store.dispatch('picture/set-origin', origin)
      store.dispatch('statuses/set-status', { picture: { uploading: false } })
      store.dispatch(router.navigate, '/effects')
    } catch (e) {
      store.dispatch('statuses/set-status', { picture: { uploading: false } })
      store.dispatch('errors/set-error', { picture: { uploading: true } })
      console.error(e)
    }
  })
}
