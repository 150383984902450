import { styled } from 'linaria/react'
import { AppLogo } from 'components/atoms'

export const Logotype = styled(AppLogo)`
  display: block;
  text-align: center;
  padding: 20px;
`

export const Main = styled.main`
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`
