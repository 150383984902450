const initialState = {
  picture: {
    uploading: false,
  },
  effects: {
    loading: false,
    processing: false,
  },
}

export function errorsModuleStore(store) {
  store.on('@init', () => ({ errors: initialState }))

  store.on('errors/set-error', ({ errors }, error) => {
    const nextState = { ...errors }

    Object.keys(error).forEach(key => {
      nextState[key] = { ...errors[key], ...error[key] }
    })

    return { errors: nextState }
  })
}
