import { API } from 'lib/api'

export const getEffects = () => API.get('/effects')

export const getResourceUrlFromOperation = async id => {
  const response = await API.get(`/operations/${id}`)

  if (response.data && response.headers) {
    if (response.data !== '' && response.headers['content-length'] !== '0') {
      return response.request.responseURL
    }
  }

  return -1
}

export const runOperation = async (mediaId, effectId) => {
  const payload = {
    media: {
      id: mediaId,
    },
    effect: {
      id: effectId,
    },
  }

  return API.post(`/operations`, { data: payload })
}
