import React from 'react'
import router from '@storeon/router'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useStoreon } from 'storeon/react'
import { ErrorMessage } from 'components/atoms'
import { BasicTemplate } from 'components/templates'
import { ImageView, Carousel } from 'components/organisms'
import { Content } from './styles'

export function EffectsPage() {
  const { errors } = useStoreon('errors')
  const { statuses } = useStoreon('statuses')
  const { picture } = useStoreon('picture')
  const { dispatch, effects } = useStoreon('effects')

  if (!picture.origin) {
    document.location = '/load'
  }

  if (effects.items.length === 0 && !statuses.effects.loading) {
    dispatch('effects/load-effects')
  }

  return (
    <BasicTemplate>
      <Content>
        <ImageView
          origin={picture.origin}
          withEffect={picture.withEffect}
          isApplying={statuses.effects.applying}
          dropOriginFn={() => {
            dispatch('picture/drop-origin')
            dispatch(router.navigate, '/load')
          }}
        />
        {errors.effects.processing && (
          <ErrorMessage text="Sorry, we could not apply effect to image. Please, try again or contact us." />
        )}
        <Carousel effects={effects.items} isLoading={statuses.effects.loading} />
      </Content>
    </BasicTemplate>
  )
}
