import { styled } from 'linaria/react'

export const Button = styled.button`
  position: relative;
  border: 2px solid #292929;
  font-size: 20px;
  color: #292929;
  font-weight: 500;
  box-shadow: 0 0 4px 1px rgba(41, 41, 41, 0.5);
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.25s;

  max-width: 180px;
  min-height: 45px;
  width: 100%;
  outline: none;

  &:hover {
    transform: rotate(-1deg) scale(1.08);
  }

  &:active {
    transform: scale(0.9) rotate(0deg);
  }
`
