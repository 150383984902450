import React from 'react'
import PropTypes from 'prop-types'
import { Main } from './styles'

const propTypes = {
  children: PropTypes.node,
}
const defaultProps = {
  children: null,
}

export function BasicTemplate({ children }) {
  return (
    <Main>
      {/* <Logotype /> */}
      {children}
    </Main>
  )
}

BasicTemplate.propTypes = propTypes
BasicTemplate.defaultProps = defaultProps
