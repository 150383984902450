import { styled } from 'linaria/react'

export const Message = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #c33131;
  border: 2px dashed #c33131;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 40px;
`
