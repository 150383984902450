import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './styles'

const propTypes = {
  onClickFn: PropTypes.func.isRequired,
}
const defaultProps = {}

export function SavePictureButton({ onClickFn }) {
  return (
    <Button type="button" onClick={onClickFn}>
      Save Picture
    </Button>
  )
}

SavePictureButton.propTypes = propTypes
SavePictureButton.defaultProps = defaultProps
