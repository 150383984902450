import React, { useState, useEffect } from 'react'
import { useStoreon } from 'storeon/react'
import { BasicTemplate } from 'components/templates'
import { ErrorMessage, SwitchSourceButton } from 'components/atoms'
import { MobileAppsAside } from 'components/molecules'
import { Dropzone, WebcamCapture } from 'components/organisms'

import { Content } from './styles'

function detectWebcam(callback) {
  const md = navigator.mediaDevices
  if (!md || !md.enumerateDevices) return callback(false)

  navigator.permissions
    .query({ name: 'camera' })
    .then(({ state }) => (state === 'granted' ? md.enumerateDevices() : []))
    .then(devices => callback(devices.some(device => device.kind === 'videoinput')))

  return undefined
}

export function HomePage() {
  const { errors } = useStoreon('errors')
  const { statuses } = useStoreon('statuses')

  const [cameraAvailable, setCameraAvailable] = useState(false)

  useEffect(() => {
    detectWebcam(hasWebcam => setCameraAvailable(hasWebcam))
  }, [setCameraAvailable])

  return (
    <BasicTemplate>
      <Content>
        {statuses.pictureSource.fromCamera ? (
          <WebcamCapture />
        ) : (
          <Dropzone isLoading={statuses.picture.uploading} />
        )}
        {errors.picture.uploading && (
          <ErrorMessage text="Sorry, we could'n upload the image. Please, try again or contact us." />
        )}
        {statuses.pictureSource.fromCamera ? (
          <></>
        ) : (
          <SwitchSourceButton
            isLoading={statuses.picture.uploading}
            cameraAvailable={cameraAvailable}
          />
        )}
      </Content>
      <MobileAppsAside />
    </BasicTemplate>
  )
}
