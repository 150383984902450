import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { Arrow } from './styles'

const propTypes = {
  direction: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
}
const defaultProps = {
  direction: 'prev',
  className: '',
  style: {},
  onClick: () => {},
}

export function CarouselArrow({ direction, className, style, onClick }) {
  return (
    <Arrow
      type="button"
      className={className}
      style={style}
      direction={direction}
      onClick={onClick}
    >
      <ArrowIcon />
    </Arrow>
  )
}

CarouselArrow.propTypes = propTypes
CarouselArrow.defaultProps = defaultProps
