import React from 'react'
import PropTypes from 'prop-types'
import { useStoreon } from 'storeon/react'

import { Button } from './styles'

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  cameraAvailable: PropTypes.bool.isRequired,
}

export function SwitchSourceButton({ isLoading, cameraAvailable }) {
  const { dispatch } = useStoreon('statuses')

  function clickHandler() {
    dispatch('changeSource')
  }

  return (
    <Button disabled={isLoading || !cameraAvailable} onClick={clickHandler}>
      Take a Photo
    </Button>
  )
}

SwitchSourceButton.propTypes = propTypes
