import { styled } from 'linaria/react'

/* <link
  href="https://fonts.googleapis.com/css?family=Grand+Hotel&display=swap&text=DeepArt"
  rel="stylesheet"
/> */

export const Title = styled.h1`
  font-family: 'Grand Hotel', cursive;
  font-size: 9.3vh;
  color: #f0600a;
  text-shadow: 1px 1px 1px #292929;
  margin: 0;
  padding: 0;

  @media (max-width: 550px) {
    padding: 20px;
  }
`
