import nanoid from 'nanoid'

export async function convertFileObjectIntoDataUrl(file) {
  const buffer = Buffer.from(await new Response(file).arrayBuffer())
  return `data:${file.type};base64,${buffer.toString('base64')}`
}

export function downloadPicture(imageUrl) {
  const a = document.createElement('a')
  a.href = imageUrl.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
  a.download = `deep-art-${nanoid()}.png`
  a.click()
}
