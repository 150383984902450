import React, { useState, useEffect } from 'react'
import router from '@storeon/router'
import { useStoreon } from 'storeon/react'
import { Wrapper, Text } from './styles'

export function NotFoundPage() {
  const { dispatch } = useStoreon()
  const [seconds, setSeconds] = useState(3)

  if (seconds === 0) {
    dispatch(router.navigate, '/load')
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(sec => sec - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Wrapper>
      <h1>This page doesn&apos;t exist.</h1>
      <Text>{`Radirect after ...${seconds}s`}</Text>
    </Wrapper>
  )
}
