import { createStoreon } from 'storeon'
import { persistState } from '@storeon/localstorage'
// import { storeonDevtools } from 'storeon/devtools'
import { router } from './Routes'
import { pictureModuleStore } from './services/Picture/picture.store'
import { effectsModuleStore } from './services/Effects/effects.store'
import { errorsModuleStore } from './services/errors.store'
import { statusesModuleStore } from './services/statuses.store'

export const store = createStoreon([
  router,
  pictureModuleStore,
  effectsModuleStore,
  errorsModuleStore,
  statusesModuleStore,
  persistState(['picture']),
  // process.env.NODE_ENV !== 'production' && storeonDevtools,
])
