import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '../Spinner'
import { Button } from './styles'

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clickFn: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
}
const defaultProps = {
  clickFn: () => {},
  className: '',
  style: {},
}

export function TakePictureButton({ isLoading, clickFn, className, style }) {
  return (
    <Button disabled={isLoading} onClick={clickFn} className={className} style={style}>
      {isLoading ? <Spinner size={3} /> : 'Take a Photo'}
    </Button>
  )
}

TakePictureButton.propTypes = propTypes
TakePictureButton.defaultProps = defaultProps
