import { styled } from 'linaria/react'

export const Content = styled.article`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex-grow: 1;
  max-width: 100vw;
`
