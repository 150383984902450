import { styled } from 'linaria/react'

const activeColor = '#292929'
const rejectColor = 'rgba(255, 0, 65, 0.8)'

const getBorderColor = props => {
  if (props.isDragReject || props.isUsedMultipleFiles) {
    return rejectColor
  }
  if (props.isDragAccept || props.isLoading) {
    return activeColor
  }
  return 'rgba(41, 41, 41, 0.5)'
}

const getFontColor = props => {
  if (props.isDragReject || props.isUsedMultipleFiles) {
    return rejectColor
  }
  if (props.isDragAccept) {
    return activeColor
  }
  return 'rgb(96, 96, 96)'
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  width: 85%;
  height: 75%;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 50px;

  cursor: default;
  border-width: 5px;
  border-radius: 8px;
  border-style: dashed;
  font-weight: 500;
  box-shadow: inset 0 0 15px 0 rgba(152, 146, 146, 0.3);
  background-color: #fafafa;

  /* stylelint-disable function-name-case */
  border-color: ${props => getBorderColor(props)};
  color: ${props => getFontColor(props)};
  /* stylelint-enable function-name-case */

  transition: border 0.25s ease-in-out, color 0.25s ease-in-out;
  outline: none;

  &:focus {
    border-color: ${activeColor};
    color: ${activeColor};
  }

  & p {
    text-align: center;
  }

  @media (max-width: 999px) {
    font-size: 3vw;
  }

  @media (max-width: 780px) {
    font-size: 4vw;
  }

  @media (max-width: 550px) {
    font-size: 6vw;

    & p {
      padding: 15px;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  @media (max-width: 360px) {
    font-size: 7vw;
  }

  @media (min-width: 1000px) {
    font-size: 2.5vw;
  }

  @media (min-width: 1600px) {
    font-size: 2.4vw;
  }

  @media (min-width: 1900px) {
    font-size: 2.2vw;
  }
`
